.navbar-light .navbar-brand {
  font-size: 2.2em;
  font-family: "FascinateInline-Regular";
}
.navbar {
  background: white;
}

.ml-auto,
.mx-auto {
  margin-left: 0 !important;
}

.animated-item2 {
  color: darkorange;
}
